import React, { useEffect, useState } from 'react'
import { Card, Descriptions, Tag, Button} from 'antd'
import { useParams } from 'react-router-dom'
import { api } from '../../../services/api'
import { DataTable, PageTitle } from '../../../components'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const StockDetail = () => {
    const history = useHistory()
    const { itemId } = useParams()
    const [loaded, setLoaded] = useState(false)
    const [stock, setStock] = useState({})

    const fetchStock = () => {
        api("GET", `inventory-stock/${itemId}`).then((res) => {
            setStock(res)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchStock()

    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Reference',
            key: 'reference',
            render: (row) => (
                row.name == 'Goods Receipt' ? 
                    <Button type='link' onClick={() => history.push(`/inventory/goods-receipt/view/${row.id}`)}>
                        {row.reference}
                    </Button>
                : row.name == 'Goods Issue' ? 
                    <Button type='link' onClick={() => history.push(`/inventory/part-request/view/${row.id}`)}>
                        {row.reference}
                    </Button>
                : row.name == 'Stock Adjustment' ? 
                    <Button type='link' onClick={() => history.push(`/inventory/stock-adjustment/view/${row.id}`)}>
                        {row.reference}
                    </Button>
                : null
            ),
            align: 'center'
        },
        {
            title: 'Movement',
            key: 'movement',
            render: (row) => (
                <Tag color={row.movement === "INCREASE" ? "green" : "red"}>{row.name}</Tag>
            ),
            align: 'center'
        },
        {
            title: 'Date',
            key: 'sku',
            render: (row) => (
                moment(row.created_at).format('YYYY-MM-DD HH:mm')
            ),
            align: 'center'
        },
        {
            title: 'Quantity',
            key: 'quantity',
            render: (row) => (
                <span style={{ color: row.movement == 'INCREASE' ? 'green' : 'red' }}>{row.movement == 'INCREASE' ? '+' : '-'}{row.quantity}</span>
            ),
            align: 'center'
        },
    ];

    return (
        <Card className="content-container">
            <PageTitle title="Stock - Movements" breadcrumbs={[['Inventory'], ['Stock', '/inventory/stocks'], ['Movements']]} />

            <Card>
                {
                    loaded ?
                        <>
                            <div style={{ marginBottom: 20 }}>
                                <Descriptions title="Information" layout="vertical" bordered>
                                    <Descriptions.Item label="SKU">{stock.sku}</Descriptions.Item>
                                    <Descriptions.Item label="Item Name">{stock.name}</Descriptions.Item>
                                    <Descriptions.Item label="Stock">{stock.quantity} {stock.uom}</Descriptions.Item>
                                </Descriptions>
                            </div>

                            <Card title="Movement">
                                <DataTable size='small' bordered dataSource={stock.movements} columns={columns} />
                            </Card>
                        </>
                        : null
                }
            </Card>
        </Card>
    )
}

export default StockDetail