import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Input, message, Row, Select, Switch } from 'antd'
import { PageTitle, Spinner, Uploader } from '../../../components'
import { api } from '../../../services/api'
import { useHistory } from 'react-router-dom'

const { Option } = Select

const CreateUser = () => {
    const history = useHistory()
    const [form] = Form.useForm()
    const [defaultValues, setDefaultValues] = useState({});
    const [department, setDepartment] = useState([])
    const [position, setPosition] = useState([])
    const [role, setRole] = useState([])
    const [status, setStatus] = useState(false)
    const [saving, setSaving] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [image, setImage] = useState('')

    const fetchDepartment = () => {
        api("GET", "departments?limit=100").then((res) => {
            setDepartment(res.data)
        })
    }

    const fetchPosition = () => {
        api("GET", "positions?limit=100").then((res) => {
            setPosition(res.data)
        })
    }

    const fetchRole = () => {
        api("GET", "roles?limit=100").then((res) => {
            setRole(res.data)
        })
    }
    useEffect(() => {
        fetchDepartment()
        fetchPosition()
        fetchRole()

    }, []);

    const fileUploaded = (v) => {
        setImage(v.data.url)
    }

    const onFinish = (v) => {
        let payload = {
            department_id: v.department_id,
            position_id: v.position_id,
            role_id: v.role_id,
            user_id: v.user_id,
            name: v.name,
            username: v.username,
            password: v.password,
            phone: v.phone,
            email: v.email,
            image: image,
            status: status ? "ACTIVE" : "NOT ACTIVE"
        }

        api("POST", "user", payload).then((res) => {
            setSaving(false)
            message.success('Successfully saved data')
            history.goBack()
        }).catch((err) => {
            setSaving(false)
            message.warning('Failed to saving data')
        })
    }

    return (
        <Card className="content-container">
            <PageTitle title="User - Create" breadcrumbs={[['Administrator'], ['User', '/users'], ['Create']]} />

            <Card title="User">
                <Form layout="vertical" onFinish={onFinish} form={form}>
                    <Form.Item name="id" hidden>
                        <Input />
                    </Form.Item>
                    <Row justify="space-between">
                        <Col span={11}>
                            <Form.Item label="Department" name="department_id" rules={[{ required: true }]}>
                                <Select placeholder="Select Department">
                                    {
                                        department.map((el, key) => (
                                            <Option key={key} value={el.id}>{el.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="Position" name="position_id" rules={[{ required: true }]}>
                                <Select placeholder="Select Position">
                                    {
                                        position.map((el, key) => (
                                            <Option key={key} value={el.id}>{el.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="space-between">
                        <Col span={11}>
                            <Form.Item label="User ID" name="user_id" rules={[{ required: true }]} >
                                <Input placeholder='User ID' />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="Role" name="role_id" rules={[{ required: true }]}>
                                <Select placeholder="Select Role">
                                    {
                                        role.map((el, key) => (
                                            <Option key={key} value={el.id}>{el.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="space-between">
                        <Col span={11}>
                            <Form.Item label="Name" name="name" rules={[{ required: true }]} >
                                <Input placeholder='Name' />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="Username" name="username" rules={[{ required: true }]} >
                                <Input placeholder='Username' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="space-between">
                        <Col span={11}>
                            <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email' }]} >
                                <Input placeholder='Email' />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="Password" name="password" rules={[
                                { required: true, message: 'Please input your password' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        let error;
                                        let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
                                        if (getFieldValue('password').length < 8) {
                                            error = 'Password minimum 8 character';
                                        } else if (regex.exec(getFieldValue('password')) == null) {
                                            error = 'Must have atleast 1 uppercase, 1 lowercase letter, 1 special character and 1 number';
                                        } else {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error(error))
                                    },
                                }),
                            ]} >
                                <Input.Password placeholder='Password' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify="space-between">
                        <Col span={11}>
                            <Form.Item label="Phone" name="phone" >
                                <Input placeholder='Phone' />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="Avatar">
                                <Uploader multiple={false} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item label="Status" name="status">
                        <Switch checkedChildren="Active" unCheckedChildren="Not Active" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status === "ACTIVE" ? true : false} />
                    </Form.Item>

                    <Form.Item>
                        {
                            uploading || saving ?
                                <Button disabled style={{ width: '20%' }} type="primary" htmlType="submit">
                                    <Spinner />
                                </Button>
                                :
                                <Button style={{ width: '20%' }} type="primary" htmlType="submit">
                                    Save
                                </Button>
                        }
                    </Form.Item>
                </Form>
            </Card>
        </Card>
    )
}

export default CreateUser