import React, { useEffect, useState } from 'react'
import { Button, Card, message, Space, Tag, Typography } from 'antd'
import { PageTitle } from '../../../components/PageTitle'
import { api } from '../../../services/api'
import { Spinner } from '../../../components/Spinner'
import { DataTable } from '../../../components/DataTable'
import { ShowButton, DownloadIconButton, DeleteButton } from '../../../components'
import { roleAccess } from '../../../helpers/menus'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

const { Text } = Typography

const PartRequest = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(false);
    const [partRequests, setPartRequests] = useState([]);
    const [data, setData] = useState([]);
    const [paginationPage, setPaginationPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const fetchPartRequest = () => {
        api("GET", "part-requests").then((res) => {
            setPartRequests(res.data)
            setData(res)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchPartRequest()

    }, []);

    const downloadReceipt = (row) => {
        api("GET", `part-request/download-receipt/${row.id}`)
            .then((res) => {
                if (res !== undefined) {
                    window.open(`${process.env.REACT_APP_API_URL}/receipts/${row.reference}.pdf`)
                } else {
                    message.warning("Document cannot be downloaded, please accept the request for download this document", 5)
                }
            })
    }

    const deletePart = (v) => {
        api("DELETE", `part-request/${v}`).then((res) => {
            message.success("Successfully deleted data")
            fetchPartRequest()
        })
    }

    const columns = [
        {
            title: 'No',
            align: 'center',
            render: (row) => (
                `${(parseFloat((paginationPage-1) * pageSize) + parseFloat(row.no))}`
            ),
            width: 50,
            fixed: 'left',
        },
        {
            title: 'Ticket Number',
            key: 'reference',
            render: (row) => (
                <Button type='link' onClick={() => history.push(`/ticket/view/${row?.ticket?.id}`)}>
                    {row?.ticket?.reference}
                </Button>
            ),
            align: 'center',
            width: 120,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 300,
        },
        {
            title: 'Requested by',
            key: 'requested',
            render: (row) => (
                row.user?.name
            ),
        },
        {
            title: 'Requested at',
            key: 'requested_at',
            render: (row) => (
                moment(row.created_at).format('YYYY-MM-DD HH:mm')
            ),
        },
        {
            title: 'Item',
            key: 'item',
            render: (row) => (
                row.items?.length
            ),
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <Tag color={text === "COMPLETED" ? "green" : "red"}>{text}</Tag>
            ),
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        {roleAccess('INV_PART_REQUEST_VIEW') ?
                            <>
                                <ShowButton onShow={() => history.push(`/inventory/part-request/view/${row.id}`)} />
                            </>
                        : null}
                        {roleAccess('INV_PART_REQUEST_DELETE') && row.status == 'REQUEST' ?
                            <>
                                <DeleteButton onConfirm={() => deletePart(row.id)} />
                            </>
                        : null}
                        <DownloadIconButton onDownload={() => downloadReceipt(row)} />
                    </Space>
                </div>
            ),
            align: 'center',
            fixed: 'right',
        },
    ];

    const handleChangePagination = (el) => {
        setLoaded(false)
        api("GET", `part-requests?page=${el.current}&limit=${el.pageSize}`).then((res) => {
            setPartRequests(res.data)
            setData(res)
            setLoaded(true)
        })
        setPaginationPage(el.current)
        setPageSize(el.pageSize)
    }

    const handleSearch = (el) => {
        var payload = {
            search: el
        }

        api("GET", `part-requests`, payload).then((res) => {
            setPartRequests(res.data)
            setData(res)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Part Requests" breadcrumbs={[['Inventory'], ['Part Requests']]} />
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Part Requests</Text>
                    </div>}>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered 
                                dataSource={partRequests} 
                                columns={columns}
                                onChange={handleChangePagination}
                                onSearch={handleSearch}
                                pagination={{
                                    total: data.total,
                                    pageSize: pageSize,
                                    current: paginationPage
                                }} />
                            :
                            <Spinner />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default PartRequest