import React, { useEffect, useState } from 'react'
import { Card, Space, Tag, Typography, Form, Input, Row, Col, Select, Switch, message, InputNumber, Popconfirm, Button } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

import { PageTitle } from '../../../components/PageTitle'
import { api } from '../../../services/api'
import { Spinner } from '../../../components/Spinner'
import { DataTable } from '../../../components/DataTable'
import { AddButton, DeleteButton, EditButton, ShowButton } from '../../../components/Button'
import { FormModal } from '../../../components/Modal'
import { roleAccess } from '../../../helpers/menus'
import moment from 'moment'

const { Text } = Typography
const { TextArea } = Input
const { Option } = Select

const Indicator = () => {
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [scoreFormModal, setScoreFormModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [indicator, setIndicator] = useState([])
    const [category, setCategory] = useState([])
    const [defaultValues, setDefaultValues] = useState({});
    const [defaultValues2, setDefaultValues2] = useState({});
    const [modalTitle, setModalTitle] = useState(null);
    const [status, setStatus] = useState(false);
    const [data, setData] = useState([]);
    const [paginationPage, setPaginationPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const fetchIndicator = () => {
        api("GET", "kpi-indicators").then((res) => {
            setIndicator(res.data)
            setData(res)
            setLoaded(true)
        })
    }

    const fetchCategory = () => {
        api("GET", "kpi-categories?limit=100").then((res) => {
            setCategory(res.data)
        })
    }

    useEffect(() => {
        fetchIndicator()
        fetchCategory()
    }, []);

    const addIndicator = () => {
        setModalTitle('Add New Indicator')
        setDefaultValues({
            id: 0,
            indicator: '',
            description: '',
            weight: '',
            status: false,
        })
        setFormModal(true)
        setStatus(false)
    }

    const editIndicator = (v) => {
        setModalTitle('Edit Indicator')
        setDefaultValues({
            id: v.id,
            category_id: v.category_id,
            indicator: v.indicator,
            description: v.description,
            weight: v.weight,
            status: v.status,
        })
        setFormModal(true)
        setStatus(v.status === "ACTIVE" ? true : false)
    }

    const deleteIndicator = (v) => {
        api("DELETE", `kpi-indicator/${v}`).then((res) => {
            message.success("Successfully deleted data")
            fetchIndicator()
        })
    }

    const saveIndicator = (v) => {
        let payload = {
            category_id: v.category_id,
            indicator: v.indicator,
            description: v.description,
            weight: v.weight,
            status: status ? "ACTIVE" : "NOT ACTIVE"
        }

        if (v.id === 0) {
            api("POST", "kpi-indicator", payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                setFormModal(false)
                fetchIndicator()
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saving data')
                setFormModal(false)
            })
        } else {
            api("PUT", `kpi-indicator/${v.id}`, payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                setFormModal(false)
                fetchIndicator()
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saving data')
                setFormModal(false)
            })
        }
    }

    const addIndicatorScore = (row) => {
        setModalTitle(`Add Score - ${row.indicator}`)
        setDefaultValues2({
            score_id:0,
            kpi_indicator_id: row.id,
            description: row.description,
            from: 0,
            to: 0,
            score: 0
        })
        setScoreFormModal(true)
    }
    const editIndicatorScore = (row, obj) => {
        setModalTitle(`Edit Score - ${row.indicator}`)
        setDefaultValues2({
            score_id: obj.id,
            kpi_indicator_id: row.id,
            description: row.description,
            from: obj.from,
            to: obj.to,
            score: obj.score
        })
        setScoreFormModal(true)
    }

    const saveIndicatorScore = (v) => {
        let payload = {
            kpi_indicator_id: v.kpi_indicator_id,
            from: v.from,
            to: v.to,
            score: v.score,
        }
        console.log(payload);
        if (v.score_id === 0) {
            api("POST", "kpi-indicator-score", payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                setScoreFormModal(false)
                fetchIndicator()
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saving data')
                setScoreFormModal(false)
            })
        } else {
            api("PUT", `kpi-indicator-score/${v.score_id}`, payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                setScoreFormModal(false)
                fetchIndicator()
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saving data')
                setScoreFormModal(false)
            })
        }
    }

    const deleteScore = () => {
        api("DELETE", `kpi-indicator-score/${defaultValues2.score_id}`).then((res) => {
            message.success("Successfully deleted data")
            setScoreFormModal(false)
            fetchIndicator()
        })

    }



    const columns = [
        {
            title: 'No',
            align: 'center',
            render: (row) => (
                `${(parseFloat((paginationPage-1) * pageSize) + parseFloat(row.no))}`
            ),
            width: 50,
            fixed: 'left',
        },
        {
            title: 'Category',
            key: 'category',
            render: (row) => (
                row.category?.name
            )
        },
        {
            title: 'Indicator',
            dataIndex: 'indicator',
            key: 'indicator'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Weight',
            key: 'weight',
            render: (row) => (
                `${row.weight}%`
            )
        },
        {
            title: 'Score',
            key: 'score',
            children: [
                {
                    title: "From",
                    align: 'center',
                    render: (row) => {
                        return (
                                <>
                                    {
                                        row.score.map((obj, index) => {
                                            return (
                                                <>
                                                    <div style={{ padding: "5px" }}>
                                                        {obj.from}
                                                    </div>
                                                </>
                                            )                                            
                                        })
                                    }
                                </>
                            );
                    }
                },
                {
                    title: "To",
                    align: 'center',
                    render: (row) => {
                        return (
                                <>
                                    {
                                        row.score.map((obj, index) => {
                                            return (
                                                <>
                                                    <div style={{ padding: "5px" }}>
                                                        {obj.to}
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </>
                            );
                    }
                },
                {
                    title: "Value",
                    align: 'center',
                    render: (row) => {
                        return (
                                <>
                                    {
                                        row.score.map((obj, index) => {
                                            return (
                                                <>
                                                    <div style={{ padding: "5px" }}>
                                                        {obj.score}
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </>
                            );
                    }
                },

                {
                    title: "#",
                    align: 'center',
                    render: (row) => {
                        return (
                                <>
                                    {
                                        row.score.map((obj, index) => {
                                            return (
                                                <div style={{ padding: "5px" }}>
                                                    <Button size="small" type="link" onClick={() => editIndicatorScore(row, obj) } icon={<EditOutlined />} /> 
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            );
                    }
                }
            ]
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <Tag color={text === "ACTIVE" ? "green" : "red"}>{(text === "ACTIVE") ? text : text}</Tag>
            ),
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        { roleAccess('KPI_INDICATOR_EDIT') ? 
                            <>
                                <ShowButton onShow={() => addIndicatorScore(row)} />
                                <EditButton onEdit={() => editIndicator(row)} />
                            </>
                        : null }
                        { roleAccess('KPI_INDICATOR_DELETE') ? 
                            <DeleteButton onConfirm={() => deleteIndicator(row.id)} />
                        : null }
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ];

    const handleChangePagination = (el) => {
        setLoaded(false)
        api("GET", `kpi-indicators?page=${el.current}&limit=${el.pageSize}`).then((res) => {
            setIndicator(res.data)
            setData(res)
            setLoaded(true)
        })
        setPaginationPage(el.current)
        setPageSize(el.pageSize)
    }

    const handleSearch = (el) => {
        var payload = {
            search: el
        }

        api("GET", `kpi-indicators`, payload).then((res) => {
            setIndicator(res.data)
            setData(res)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Indicators" breadcrumbs={[['KPI'], ['Indicators']]} />
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Indicators</Text>
                        { roleAccess('KPI_INDICATOR_ADD') ? 
                            <AddButton right onAdd={addIndicator} title="New Indicator" />
                        : null }
                    </div>}>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered 
                                dataSource={indicator} 
                                columns={columns}
                                onChange={handleChangePagination}
                                onSearch={handleSearch}
                                pagination={{
                                    total: data.total,
                                    pageSize: pageSize,
                                    current: paginationPage
                                }} />
                            :
                            <Spinner />
                    }
                </Card>

                {
                    (formModal) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveIndicator(v)} width={500} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Category" name="category_id" rules={[{ required: true }]}>
                                <Select placeholder="Select Category">
                                    {
                                        category.map((el, key) => (
                                            <Option key={key} value={el.id}>{el.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Indicator" name="indicator" rules={[{ required: true }]}>
                                <Input placeholder="Indicator" />
                            </Form.Item>
                            <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                                <TextArea placeholder="Description" rows={7}/>
                            </Form.Item>
                            <Form.Item label="Weight (%)" name="weight" rules={[{ required: true }]}>
                                <InputNumber placeholder="Weight (%)" style={{ width: '100%' }}/>
                            </Form.Item>
                            <Form.Item label="Status" name="status">
                                <Switch checkedChildren="Active" unCheckedChildren="Not Active" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status === "ACTIVE" ? true : false} />
                            </Form.Item>
                        </FormModal> :
                        null
                }

                {
                    (scoreFormModal) ?
                        <FormModal defaultValues={defaultValues2} title={modalTitle} submitForm={(v) => saveIndicatorScore(v)} width={500} showModal={scoreFormModal} onCloseModal={() => setScoreFormModal(false)} saving={saving}>
                            
                            <Row justify="space-between">
                                <Col span={24}>
                                    <Popconfirm title={"Delete this score?"} okText="Yes" cancelText="No" onConfirm={ deleteScore }>
                                        <Button type="primary" danger size="small" style={{ float: "right" }} icon={<DeleteOutlined/>}> Delete </Button>
                                    </Popconfirm>
                                </Col>
                            </Row>
                            
                            <Form.Item name="score_id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item name="kpi_indicator_id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Description" name="description">
                                <TextArea disabled placeholder="Description" rows={7}/>
                            </Form.Item>
                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="Value From" name="from" rules={[{ required: true }]}>
                                        <InputNumber placeholder="Value From" style={{ width: '100%' }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Value To" name="to" rules={[{ required: true }]}>
                                        <InputNumber placeholder="Value To" style={{ width: '100%' }}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item label="Score" name="score" rules={[{ required: true }]}>
                                <InputNumber placeholder="Score"  style={{ width: '100%' }}/>
                            </Form.Item>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default Indicator