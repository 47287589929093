import React, { useEffect, useState } from 'react'
import { Card, Space, Tag, Typography, Form, Input, Switch, Avatar, message, Select, InputNumber } from 'antd'
import { PageTitle, Uploader } from '../../../components'
import { api } from '../../../services/api'
import { Spinner } from '../../../components/Spinner'
import { DataTable } from '../../../components/DataTable'
import { AddButton, DeleteButton, EditButton } from '../../../components/Button'
import { FormModal } from '../../../components/Modal'
import { roleAccess } from '../../../helpers/menus'

const { Text } = Typography
const { TextArea } = Input
const { Option } = Select

const Item = () => {
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [item, setItem] = useState([])
    const [category, setCategory] = useState([])
    const [scope, setScope] = useState([])
    const [uom, setUom] = useState([])
    const [defaultValues, setDefaultValues] = useState({});
    const [modalTitle, setModalTitle] = useState(null);
    const [status, setStatus] = useState(false);
    const [uploading, setUploading] = useState(false)
    const [image, setImage] = useState('')
    const [data, setData] = useState([]);
    const [paginationPage, setPaginationPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const fetchItem = () => {
        api("GET", "items").then((res) => {
            setItem(res.data)
            setData(res)
            setLoaded(true)
        })
    }

    const fetchCategory = () => {
        api("GET", "categories?limit=100").then((res) => {
            setCategory(res.data)
        })
    }

    const fetchScope = () => {
        api("GET", "scopes").then((res) => {
            setScope(res.data)
        })
    }

    const fetchUom = () => {
        api("GET", "uoms?limit=100").then((res) => {
            setUom(res.data)
        })
    }

    useEffect(() => {
        fetchItem()
        fetchCategory()
        fetchScope()
        fetchUom()

    }, []);

    const fileUploaded = (v) => {
        setImage(v.data.url)
    }

    const addItem = () => {
        setModalTitle('Add New Item')
        setDefaultValues({
            id: 0,
            sku: '',
            name: '',
            description: '',
            price: 0,
            status: false,
        })
        setFormModal(true)
        setStatus(false)
    }

    const editItem = (v) => {
        setModalTitle('Edit Item')
        setDefaultValues({
            id: v.id,
            category_id: v.category_id,
            scope_id: v.scope_id,
            uom_id: v.uom_id,
            sku: v.sku,
            name: v.name,
            description: v.description,
            price: v.price,
            status: v.status,
        })
        setFormModal(true)
        setStatus(v.status === "ACTIVE" ? true : false)
    }

    const deleteItem = (v) => {
        api("DELETE", `item/${v}`).then((res) => {
            message.success("Successfully deleted data")
            fetchItem()
        })
    }

    const saveItem = (v) => {
        let payload = {
            category_id: v.category_id,
            scope_id: v.scope_id,
            uom_id: v.uom_id,
            sku: v.sku,
            name: v.name,
            description: v.description,
            price: v.price,
            image: image,
            status: status ? "ACTIVE" : "NOT ACTIVE"
        }

        if (v.id === 0) {
            api("POST", "item", payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                setFormModal(false)
                fetchItem()
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saving data')
                setFormModal(false)
            })
        } else {
            api("PUT", `item/${v.id}`, payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                setFormModal(false)
                fetchItem()
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saving data')
                setFormModal(false)
            })
        }
    }

    const columns = [
        {
            title: 'No',
            align: 'center',
            render: (row) => (
                `${(parseFloat((paginationPage-1) * pageSize) + parseFloat(row.no))}`
            ),
            width: 50,
            fixed: 'left',
        },
        {
            title: 'Image',
            key: 'image',
            render: (row) => (
                <Avatar
                    style={{
                        verticalAlign: 'middle',
                    }}
                    size="large"
                    src={row.image}/>
            ),
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Scope',
            key: 'scope',
            render: (row) => (
                row?.scope === null ? '-' : row.scope.name
            ),
            align: 'center'
        },
        {
            title: 'Category',
            key: 'category',
            render: (row) => (
                row?.category === null ? '-' : row.category.name
            ),
            align: 'center'
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <Tag color={text === "ACTIVE" ? "green" : "red"}>{(text === "ACTIVE") ? text : text}</Tag>
            ),
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        { roleAccess('INV_ITEM_EDIT') ? 
                            <EditButton onEdit={() => editItem(row)} />
                        : null }
                        { roleAccess('INV_ITEM_DELETE') ? 
                            <DeleteButton onConfirm={() => deleteItem(row.id)} />
                        : null }
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ];

    const handleChangePagination = (el) => {
        setLoaded(false)
        api("GET", `items?page=${el.current}&limit=${el.pageSize}`).then((res) => {
            setItem(res.data)
            setData(res)
            setLoaded(true)
        })
        setPaginationPage(el.current)
        setPageSize(el.pageSize)
    }

    const handleSearch = (el) => {
        var payload = {
            search: el
        }

        api("GET", `items`, payload).then((res) => {
            setItem(res.data)
            setData(res)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Items" breadcrumbs={[['Inventory'], ['Items']]} />
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Items</Text>
                        { roleAccess('INV_ITEM_ADD') ? 
                            <AddButton right onAdd={addItem} title="New Item" />
                        : null }
                    </div>}>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered 
                                dataSource={item} 
                                columns={columns}
                                onChange={handleChangePagination}
                                onSearch={handleSearch}
                                pagination={{
                                    total: data.total,
                                    pageSize: pageSize,
                                    current: paginationPage
                                }} />
                            :
                            <Spinner />
                    }
                </Card>

                {
                    (formModal) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveItem(v)} width={500} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Scope" name="scope_id" rules={[{ required: true }]}>
                                <Select placeholder="Select Scope">
                                    {
                                        scope.map((el, key) => (
                                            <Option key={key} value={el.id}>{el.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Category" name="category_id" rules={[{ required: true }]}>
                                <Select placeholder="Select Category">
                                    {
                                        category.map((el, key) => (
                                            <Option key={key} value={el.id}>{el.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="SKU" name="sku" rules={[{ required: true }]} >
                                <Input placeholder='SKU' />
                            </Form.Item>
                            <Form.Item label="Name" name="name" rules={[{ required: true }]} >
                                <Input placeholder='Name' />
                            </Form.Item>
                            <Form.Item label="Description" name="description">
                                <TextArea placeholder='Description' rows={4}/>
                            </Form.Item>
                            <Form.Item label="Price" name="price">
                                <InputNumber style={{ width: '100%' }} placeholder='Price' />
                            </Form.Item>
                            <Form.Item label="Uom" name="uom_id" rules={[{ required: true }]}>
                                <Select placeholder="Select Uom">
                                    {
                                        uom.map((el, key) => (
                                            <Option key={key} value={el.id}>{el.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Image">
                                <Uploader multiple={false} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                            </Form.Item>
                            <Form.Item label="Status" name="status">
                                <Switch checkedChildren="Active" unCheckedChildren="Not Active" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status === "ACTIVE" ? true : false} />
                            </Form.Item>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default Item