import React, { useEffect, useState } from 'react'
import { Card, Space, Tag, Typography, Form, Input, Switch, message } from 'antd'
import { PageTitle } from '../../../components/PageTitle'
import { api } from '../../../services/api'
import { Spinner } from '../../../components/Spinner'
import { DataTable } from '../../../components/DataTable'
import { AddButton, DeleteButton, EditButton } from '../../../components/Button'
import { FormModal } from '../../../components/Modal'
import { roleAccess } from '../../../helpers/menus'
import moment from 'moment'

const { Text } = Typography

const CannedReply = () => {
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [cannedReply, setCannedReply] = useState([])
    const [defaultValues, setDefaultValues] = useState({});
    const [modalTitle, setModalTitle] = useState(null);
    const [status, setStatus] = useState(false);
    const [data, setData] = useState([]);
    const [paginationPage, setPaginationPage] = useState(1);

    const fetchCannedReply = () => {
        api("GET", "canned-replies").then((res) => {
            setCannedReply(res.data)
            setData(res)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchCannedReply()

    }, []);

    const addCannedReply = () => {
        setModalTitle('Add New Canned Reply')
        setDefaultValues({
            id: 0,
            name: '',
            description: '',
            status: false,
        })
        setFormModal(true)
        setStatus(false)
    }

    const editCannedReply = (v) => {
        setModalTitle('Edit Canned Reply')
        setDefaultValues({
            id: v.id,
            name: v.name,
            description: v.description,
            status: v.status,
        })
        setFormModal(true)
        setStatus(v.status === "ACTIVE" ? true : false)
    }

    const deleteCannedReply = (v) => {
        api("DELETE", `canned-reply/${v}`).then((res) => {
            message.success("Successfully deleted data")
            fetchCannedReply()
        })
    }

    const saveCannedReply = (v) => {
        let payload = {
            name: v.name,
            description: v.description,
            status: status ? "ACTIVE" : "NOT ACTIVE"
        }

        if (v.id === 0) {
            api("POST", "canned-reply", payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                setFormModal(false)
                fetchCannedReply()
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saving data')
                setFormModal(false)
            })
        } else {
            api("PUT", `canned-reply/${v.id}`, payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                setFormModal(false)
                fetchCannedReply()
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saving data')
                setFormModal(false)
            })
        }
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <Tag color={text === "ACTIVE" ? "green" : "red"}>{(text === "ACTIVE") ? text : text}</Tag>
            ),
            align: 'center'
        },
        {
            title: 'Created At',
            key: 'created_at',
            render: (row) => (
                moment(row.created_at).format('YYYY-MM-DD HH:mm')
            ),
            width: 150
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        { roleAccess('MASTER_CANNED_REPLY_EDIT') ? 
                            <EditButton onEdit={() => editCannedReply(row)} />
                        : null }
                        { roleAccess('MASTER_CANNED_REPLY_DELETE') ? 
                            <DeleteButton onConfirm={() => deleteCannedReply(row.id)} />
                        : null }
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ];
    
    const handleChangePagination = (el) => {
        setLoaded(false)
        api("GET", `canned-replies?page=${el.current}`).then((res) => {
            setCannedReply(res.data)
            setLoaded(true)
        })
        setPaginationPage(el.current)
    }

    const handleSearch = (el) => {
        var payload = {
            search: el
        }

        api("GET", `canned-replies`, payload).then((res) => {
            setCannedReply(res.data)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Canned Replies" breadcrumbs={[['Master'], ['Canned Replies']]} />
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Canned Replies</Text>
                        { roleAccess('MASTER_CANNED_REPLY_ADD') ? 
                            <AddButton right onAdd={addCannedReply} title="New Canned Reply" />
                        : null }
                    </div>}>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered 
                                dataSource={cannedReply}
                                columns={columns} 
                                onChange={handleChangePagination}
                                onSearch={handleSearch}
                                pagination={{
                                    total: data.total,
                                    current: paginationPage
                                }} />
                            :
                            <Spinner />
                    }
                </Card>

                {
                    (formModal) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveCannedReply(v)} width={500} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                                <Input placeholder="Name" />
                            </Form.Item>
                            <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                                <Input.TextArea placeholder="Description" rows={5} />
                            </Form.Item>
                            <Form.Item label="Status" name="status">
                                <Switch checkedChildren="Active" unCheckedChildren="Not Active" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status === "ACTIVE" ? true : false} />
                            </Form.Item>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default CannedReply