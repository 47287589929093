import React, { useEffect, useState } from 'react'
import { Avatar, Button, Card, Col, DatePicker, Form, Input, InputNumber, message, Row } from 'antd'
import { BasicModal, DataTable, PageTitle } from '../../../components'
import { api } from '../../../services/api'
import { useHistory } from 'react-router-dom'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import moment from 'moment/moment'

const CreateGoodsReceipt = () => {
    const history = useHistory()
    const [items, setItems] = useState([])
    const [itemSelected, setItemSelected] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [status, setStatus] = useState('')
    const [data, setData] = useState([]);
    const [paginationPage, setPaginationPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const fetchItem = () => {
        api("GET", "inventory-stocks").then((res) => {
            let items = []
            res?.data?.map((item, i) => {
                items.push({
                    ...item,
                    old_quantity: item.quantity,
                })
            })
            setItems(items)
            setData(res)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchItem()

    }, []);

    const showItems = () => {
        setFormModal(true)
        setModalTitle('Items')
    }

    const selectItem = (v) => {
        const newItm = []
        const itm = itemSelected
        newItm.push(v)

        const allItem = itm.concat(newItm)
        setItemSelected(allItem)
    }

    const changeQuantity = (q, row) => {
        const itemFind = itemSelected.findIndex(e => {
            return e.id === row.id;
        });

        Object.assign(itemSelected[itemFind], { quantity: q, item_id: row.id })
    }

    const removeItem = (v) => {
        const itm = itemSelected
        setItemSelected(
            itm.filter(a =>
                a.id !== v
            )
        );
    }

    const saveGoodsReceipt = (v) => {

        if(itemSelected?.length > 0){
            let payload = {
                date: moment(v.date).format('YYYY-MM-DD HH:mm'),
                remark: v.remark,
                items: itemSelected,
                status: status
            }

            api("POST", "goods-receipt", payload).then((res) => {
                message.success('Successfully saved data')
                history.push('/inventory/goods-receipts')
            }).catch((err) => {
                message.warning('Failed to saving data')
            })
        } else {
            message.warning('Please select items')
        }
    }

    const columns = [
        {
            title: 'No',
            align: 'center',
            render: (row) => (
                `${(parseFloat((paginationPage-1) * pageSize) + parseFloat(row.no))}`
            ),
            width: 50,
            fixed: 'left',
        },
        {
            title: 'Image',
            key: 'sku',
            render: (row) => (
                <Avatar
                    style={{
                        verticalAlign: 'middle',
                    }}
                    size="large"
                    src={row.image}
                />
            ),
            align: 'center',
            width: 80
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Quantity',
            key: 'quantity',
            render: (row) => (
                `${row.quantity} ${row.uom}`
            ),
            align: 'center'
        },
        {
            title: 'Action',
            key: 'action',
            render: (row) => (
                itemSelected.find(e =>
                    e.id === row.id) === undefined ?
                    <Button shape='circle' type='primary' onClick={() => selectItem(row)} icon={<PlusCircleOutlined />} />
                    :
                    <Button shape='circle' type='primary' danger onClick={() => removeItem(row.id)} icon={<MinusCircleOutlined />} />
            ),
            align: 'center',
            width: 80
        }
    ];

    const columnsSelectedItem = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Image',
            key: 'sku',
            render: (row) => (
                <Avatar
                    style={{
                        verticalAlign: 'middle',
                    }}
                    size="large"
                    src={row.image}
                />
            ),
            align: 'center',
            width: 80
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Quantity',
            key: 'quantity',
            render: (row) => (
                <span>
                    <InputNumber onChange={(v) => changeQuantity(v, row)} style={{ width: '70%' }} /> {row.uom}
                </span>
            ),
            align: 'center',
            width: 350
        },
        {
            title: "Action",
            key: "action",
            render: (row) => (
                <Button type='primary' danger shape='circle' icon={<MinusCircleOutlined />} onClick={() => removeItem(row.id)} />
            ),
            align: 'center',
            width: 70
        }
    ];


    const handleChangePagination = (el) => {
        api("GET", `inventory-stocks?page=${el.current}&limit=${el.pageSize}`).then((res) => {
            setItems(res.data)
            setData(res)
        })
        setPaginationPage(el.current)
        setPageSize(el.pageSize)
    }

    const handleSearch = (el) => {
        var payload = {
            search: el
        }

        api("GET", `inventory-stocks`, payload).then((res) => {
            setItems(res.data)
            setData(res)
        })
    }

    return (
        <Card className="content-container">
            <PageTitle title="Goods Receipt - Create" breadcrumbs={[['Inventory'], ['Goods Receipt', '/inventory/goods-receipts'], ['Create']]} />
            {
                loaded ?
                    <Form layout='vertical' onFinish={(v) => saveGoodsReceipt(v)}>
                        <Card>
                            <Row justify='start'>
                                <Col span={11}>
                                    <Form.Item label="Date" name="date" rules={[{ required: true }]}>
                                        <DatePicker style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='start'>
                                <Col span={11}>
                                    <Form.Item label="Remark" name="remark" rules={[{ required: true }]}>
                                        <Input.TextArea placeholder='Remark' rows={5} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='end' style={{ marginBottom: 20 }}>
                                <Col>
                                    <Button className='button-handling' type='primary' onClick={showItems}>Add Item</Button>
                                </Col>
                            </Row>

                            <Card title='Items' style={{ marginBottom: 20 }}>
                                {
                                    itemSelected.length !== 0 ?
                                        <DataTable search={false} pagination={false} size='small' bordered dataSource={itemSelected} columns={columnsSelectedItem} />
                                        : null
                                }
                            </Card>

                            <Form.Item>
                                <Button style={{ width: '20%' }} className='button-handling' type='primary' htmlType='submit' name="status" onClick={() => setStatus("ACTIVE")}>
                                    Save
                                </Button>
                            </Form.Item>

                            {
                                <BasicModal title={modalTitle} width={1000} showModal={formModal} onCloseModal={() => setFormModal(false)}>
                                    <DataTable size='small' bordered 
                                        dataSource={items} 
                                        columns={columns}
                                        onChange={handleChangePagination}
                                        onSearch={handleSearch}
                                        pagination={{
                                            total: data.total,
                                            pageSize: pageSize,
                                            current: paginationPage
                                        }} />
                                </BasicModal>
                            }
                        </Card>
                    </Form>
                    : null
            }
        </Card>
    )
}

export default CreateGoodsReceipt