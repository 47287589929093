import React, { useState, useEffect } from 'react';
import { Table, Input } from 'antd'

const { Search } = Input;

export const DataTable = (props) => {
    const { dataSource, columns, onChange, onSearch, current, size = 'middle', bordered = true, pagination, scroll = {}, search = true } = props
    const [data, setData] = useState([]);

    useEffect(() => {
        let x = dataSource
        x.forEach((el, key) => {
            el.key = key;
            el.no = key + 1;
        });
        setData(x)
    }, [dataSource]);

    const onChangeSearch = v => {
        if (!data || data.length === 0) {
            let x = dataSource
            x.forEach((el, key) => {
                el.key = key;
            });
            setData(x)
        } else {
            if (!v) {
                let x = dataSource
                x.forEach((el, key) => {
                    el.key = key;
                });
                setData(x)
                setData(dataSource)
            } {
                const fieldColumns = Object.keys(data[0])
                let newData = [];
                fieldColumns.forEach(column => {
                    dataSource.forEach(data => {
                        let text = ''
                        if (data[column]) {
                            text = data[column];
                        }
                        if (text.toString().toLowerCase().includes(v.toLowerCase()) && !newData.includes(data)) {
                            newData.push(data)
                        }
                    })
                })
                setData(newData)
            }
        }
    }

    return (
        <div className="full-width">
            {
                (search) ?
                    <div className="full-width" style={{ width: '100%', display: 'inline-block' }}>
                        <Search placeholder="Search" enterButton style={{ width: '300px', float: 'right' }} 
                            onChange={(e) => 
                                onSearch(e.target.value)
                            } />
                        <br /><br />
                    </div>
                    : null
            }
            <Table scroll={scroll} 
                bordered={bordered} 
                dataSource={data} 
                columns={columns} 
                onChange={onChange} 
                size={size} 
                pagination={pagination} />
        </div>
    );
}