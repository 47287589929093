import React, { useEffect, useState } from 'react'
import { Avatar, Button, Card, Col, Form, Input, InputNumber, message, Row, Space } from 'antd'
import { BasicModal, DataTable, ImportXlsx, PageTitle } from '../../../components'
import { api } from '../../../services/api'
import { useHistory } from 'react-router-dom'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import FileSample from '../../../assets/docs/sample.xlsx'

const CreateStockAdjusment = () => {
    const history = useHistory()
    const [items, setItems] = useState([])
    const [itemSelected, setItemSelected] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [status, setStatus] = useState('')
    const [data, setData] = useState([]);
    const [paginationPage, setPaginationPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const fetchItem = () => {
        api("GET", "inventory-stocks").then((res) => {
            let items = []
            res?.data?.map((item, i) => {
                items.push({
                    ...item,
                    item_id: item.id,
                    old_quantity: item.quantity,
                    new_quantity: item.quantity
                })
            })

            setItems(items)
            setData(res)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchItem()

    }, []);


    const fileUploaded = (v) => {
        let result = []
        v.data.map((item) => {
            result.push({
                ...item,
                item_id: item.id,
                old_quantity: item.quantity,
                new_quantity: item.input_quantity,
                quantity: item.input_quantity - item.quantity
            })
        })

        setItemSelected(result)
    }

    const showItems = () => {
        setFormModal(true)
        setModalTitle('Items')
    }

    const selectItem = (v) => {
        const newItm = []
        const itm = itemSelected
        newItm.push(v)

        const allItem = itm.concat(newItm)
        setItemSelected(allItem)
    }

    const changeQuantity = (q, row) => {
        let items = []
        itemSelected.map((item, i) => {
            if (item.item_id !== row.item_id) {
                items.push(item)
            } else {
                items.push({
                    ...item,
                    new_quantity: parseFloat(item.quantity) + parseFloat(q)
                })
            }

        })

        setItemSelected(items)
    }

    const removeItem = (v) => {
        const itm = itemSelected
        setItemSelected(
            itm.filter(a =>
                a.item_id !== v
            )
        );
    }

    const saveStockAdjustment = (v) => {
        if (itemSelected?.length > 0) {
            let payload = {
                remark: v.remark,
                items: itemSelected,
                status: status
            }

            api("POST", "stock-adjustment", payload).then((res) => {
                message.success('Successfully saved data')
                history.goBack()
            }).catch((err) => {
                message.warning('Failed to saving data')
            })

        } else {
            message.warning('Please select items')
        }
    }

    const columns = [
        {
            title: 'No',
            align: 'center',
            render: (row) => (
                `${(parseFloat((paginationPage-1) * pageSize) + parseFloat(row.no))}`
            ),
            width: 50,
            fixed: 'left',
        },
        {
            title: 'Image',
            key: 'sku',
            render: (row) => (
                <Avatar
                    style={{
                        verticalAlign: 'middle',
                    }}
                    size="large"
                    src={row.image}
                />
            ),
            align: 'center',
            width: 80
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center'
        },
        {
            title: 'Quantity',
            key: 'quantity',
            render: (row) => (
                `${row.quantity} ${row.uom}`
            ),
            align: 'center'
        },
        {
            title: 'Action',
            key: 'action',
            render: (row) => (
                itemSelected.find(e =>
                    e.id === row.id) === undefined ?
                    <Button shape='circle' type='primary' onClick={() => selectItem(row)} icon={<PlusCircleOutlined />} />
                    :
                    <Button shape='circle' type='primary' danger onClick={() => removeItem(row.item_id)} icon={<MinusCircleOutlined />} />
            ),
            align: 'center',
            width: 80
        }
    ];

    const columnsSelectedItem = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Image',
            key: 'sku',
            render: (row) => (
                <Avatar
                    style={{
                        verticalAlign: 'middle',
                    }}
                    size="large"
                    src={row.image}
                />
            ),
            align: 'center',
            width: 80
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            width: 100
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            name: 300
        },
        {
            title: 'Old Quantity',
            key: 'old_quantity',
            render: (row) => (
                `${parseFloat(row.old_quantity)} ${row.uom}`
            ),
            width: 120,
            align: 'center'
        },
        {
            title: 'Quantity',
            key: 'quantity',
            render: (row) => (
                <InputNumber value={row.quantity} onChange={(v) => changeQuantity(v, row)} style={{ width: '90%' }} />
            ),
            align: 'center',
            width: 120
        },
        {
            title: 'New Quantity',
            key: 'new_quantity',
            render: (row) => (
                `${row.new_quantity ? row.new_quantity : 0} ${row.uom}`
            ),
            align: 'center',
        },
        {
            title: "Action",
            key: "action",
            render: (row) => (
                <Button type='primary' danger shape='circle' icon={<MinusCircleOutlined />} onClick={() => removeItem(row.item_id)} />
            ),
            align: 'center',
            width: 70
        }
    ];

    const handleChangePagination = (el) => {
        api("GET", `inventory-stocks?page=${el.current}&limit=${el.pageSize}`).then((res) => {
            setItems(res.data)
            setData(res)
        })
        setPaginationPage(el.current)
        setPageSize(el.pageSize)
    }

    const handleSearch = (el) => {
        var payload = {
            search: el
        }

        api("GET", `inventory-stocks`, payload).then((res) => {
            setItems(res.data)
            setData(res)
        })
    }

    return (
        <Card className="content-container">
            <PageTitle title="Stock Adjustment - Create" breadcrumbs={[['Inventory'], ['Stock Adjustment', '/inventory/stock-adjustments'], ['Create']]} />
            {
                loaded ?
                    <Form layout='vertical' onFinish={(v) => saveStockAdjustment(v)}>
                        <Card>
                            <Row justify='start'>
                                <Col span={11}>
                                    <Form.Item label="Remark" name="remark" rules={[{ required: true }]}>
                                        <Input.TextArea placeholder='Remark' rows={5} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='mb-2' justify='end'>
                                <Button className="button-completed" type="primary">
                                    <a href={FileSample} target="_blank" downoad="Format_file.xlsx">
                                        Download Template
                                    </a>
                                </Button>
                            </Row>
                            <Row justify='end' style={{ marginBottom: 20 }}>
                                <Space>
                                    <Button className='button-handling' type='primary' onClick={showItems}>Add Item</Button>
                                    <ImportXlsx title="Import Excel" multiple={false} onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                                    {
                                        itemSelected.length > 0 ?
                                            <Button type="primary" danger onClick={() => setItemSelected([])}>Reset</Button>
                                            :
                                            <Button disabled type="primary" danger onClick={() => setItemSelected([])}>Reset</Button>
                                    }
                                </Space>
                            </Row>

                            <Card title='Items' style={{ marginBottom: 20 }}>
                                {
                                    itemSelected.length !== 0 ?
                                        <DataTable search={false} pagination={false} 
                                            size='small' bordered 
                                            dataSource={itemSelected} 
                                            columns={columnsSelectedItem} />
                                        : null
                                }
                            </Card>

                            <Form.Item>
                                <Button style={{ width: '20%' }} className='button-handling' type='primary' htmlType='submit' name="status" onClick={() => setStatus("ACTIVE")}>
                                    Save
                                </Button>
                            </Form.Item>

                            {
                                <BasicModal title={modalTitle} width={1000} showModal={formModal} onCloseModal={() => setFormModal(false)}>
                                    <div style={{ marginBottom: 20 }}>
                                        <DataTable size='small' bordered 
                                            dataSource={items} 
                                            columns={columns}
                                            onChange={handleChangePagination}
                                            onSearch={handleSearch}
                                            pagination={{
                                                total: data.total,
                                                pageSize: pageSize,
                                                current: paginationPage
                                            }} />
                                    </div>
                                </BasicModal>
                            }
                        </Card>
                    </Form>
                    : null
            }
        </Card>
    )
}

export default CreateStockAdjusment