import React, { useEffect, useState } from 'react'
import { Card, Space, Tag, Typography, Form, Input, Switch, message, Image } from 'antd'
import { PageTitle } from '../../../components/PageTitle'
import { api } from '../../../services/api'
import { Spinner } from '../../../components/Spinner'
import { DataTable } from '../../../components/DataTable'
import { AddButton, DeleteButton, EditButton } from '../../../components/Button'
import { FormModal, Uploader } from '../../../components'
import { roleAccess } from '../../../helpers/menus'
import { useParams } from 'react-router-dom'
import moment from 'moment'

const { Text } = Typography
const { TextArea } = Input;

const Procedure = () => {
    const { scopeId } = useParams()
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [procedures, setProcedures] = useState([])
    const [defaultValues, setDefaultValues] = useState({});
    const [modalTitle, setModalTitle] = useState(null);
    const [pdf, setPdf] = useState('')
    const [uploading, setUploading] = useState(false)
    const [status, setStatus] = useState(false);
    const [data, setData] = useState([]);
    const [paginationPage, setPaginationPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const fetchProcedure = () => {
        api("GET", `lsr-procedures`).then((res) => {
            setProcedures(res.data)
            setData(res)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchProcedure()
    }, []);

    const addProcedure = () => {
        setModalTitle('Add New LSR Procedure')
        setDefaultValues({
            id: 0,
            title: '',
            description: '',
            status: false,
        })
        setFormModal(true)
        setStatus(false)
    }

    const editProcedure = (v) => {
        setModalTitle('Edit LSR Procedure')

        setDefaultValues({
            id: v.id,
            title: v.title,
            description: v.description,
            status: v.status === "ACTIVE" ? true : false
        })

        setFormModal(true)
        setStatus(v.status === "ACTIVE" ? true : false)
    }

    const deleteProcedure = (v) => {
        api("DELETE", `lsr-procedure/${v}`).then((res) => {
            message.success("Successfully deleted data")
            fetchProcedure()
        })
    }

    const fileUploaded = (v) => {
        setPdf(v.data.url)
    }

    const saveProcedure = (v) => {
        let payload = {
            title: v.title,
            description: v.description,
            pdf: pdf,
            status: status ? "ACTIVE" : "NOT ACTIVE",
        }

        if (v.id === 0) {
            api("POST", "lsr-procedure", payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                setFormModal(false)
                fetchProcedure()
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saving data')
                setFormModal(false)
            })
        } else {
            api("PUT", `lsr-procedure/${v.id}`, payload).then((res) => {
                setSaving(false)
                message.success('Successfully saved data')
                setFormModal(false)
                fetchProcedure()
            }).catch((err) => {
                setSaving(false)
                message.warning('Failed to saving data')
                setFormModal(false)
            })
        }
    }

    const columns = [
        {
            title: 'No',
            align: 'center',
            render: (row) => (
                `${(parseFloat((paginationPage-1) * pageSize) + parseFloat(row.no))}`
            ),
            width: 50,
            fixed: 'left',
        },
        {
            title: 'Image',
            align: 'center',
            key: 'image',
            render: (row) => (
                <Image
                    style={{ cursor: "pointer" }}
                    preview={false}
                    width={80}
                    src={`${process.env.REACT_APP_PUBLIC_URL}/pdf.png`}
                    onClick={() => window.open(`${row.pdf}`)}
                />
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <Tag color={text === "ACTIVE" ? "green" : "red"}>{(text === "ACTIVE") ? text : text}</Tag>
            ),
            align: 'center'
        },
        {
            title: 'Created At',
            key: 'created_at',
            render: (row) => (
                moment(row.created_at).format('YYYY-MM-DD HH:mm')
            ),
            width: 150
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        {roleAccess('MASTER_LSR_PROCEDURE_EDIT') ?
                            <>
                                <EditButton onEdit={() => editProcedure(row)} />
                            </>
                            : null}
                        {roleAccess('MASTER_LSR_PROCEDURE_DELETE') ?
                            <DeleteButton onConfirm={() => deleteProcedure(row.id)} />
                            : null}
                    </Space>
                </div>
            ),
            align: 'center'
        },
    ];

    const handleChangePagination = (el) => {
        setLoaded(false)
        api("GET", `lsr-procedures?page=${el.current}&limit=${el.pageSize}`).then((res) => {
            setProcedures(res.data)
            setData(res)
            setLoaded(true)
        })
        setPaginationPage(el.current)
        setPageSize(el.pageSize)
    }

    const handleSearch = (el) => {
        var payload = {
            search: el
        }

        api("GET", `lsr-procedures`, payload).then((res) => {
            setProcedures(res.data)
            setData(res)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="LSR Procedures" breadcrumbs={[['Master'], ['LSR Procedures']]} />
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>LSR Procedures</Text>
                        {roleAccess('MASTER_LSR_PROCEDURE_ADD') ?
                            <AddButton right onAdd={addProcedure} title="New LSR Procedure" />
                            : null}
                    </div>}>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered 
                                dataSource={procedures} 
                                columns={columns}
                                onChange={handleChangePagination}
                                onSearch={handleSearch}
                                pagination={{
                                    total: data.total,
                                    pageSize: pageSize,
                                    current: paginationPage
                                }} />
                            :
                            <Spinner />
                    }
                </Card>

                {
                    (formModal) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveProcedure(v)} width={500} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Title" name="title" rules={[{ required: true }]}>
                                <Input placeholder="Title" />
                            </Form.Item>
                            <Form.Item label="Description" name="description">
                                <TextArea placeholder="Description" rows={7} />
                            </Form.Item>
                            <Form.Item label="Pdf">
                                <Uploader accept=".pdf" multiple={false} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                            </Form.Item>
                            <Form.Item label="Status" name="status">
                                <Switch checkedChildren="Active" unCheckedChildren="Not Active" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status === "ACTIVE" ? true : false} />
                            </Form.Item>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default Procedure