import React, { useEffect, useState } from 'react'
import { Card, Space, Tabs, Typography, Button, Drawer, Row, Col, Form, DatePicker, Modal, Statistic } from 'antd'
import { FileExcelOutlined, FilterOutlined, InfoCircleOutlined, FileDoneOutlined } from '@ant-design/icons';
import { PageTitle } from '../../../components/PageTitle'
import { Spinner } from '../../../components/Spinner'
import { DataTable } from '../../../components/DataTable'
import { api } from '../../../services/api'
import moment from 'moment'
import './style.css'

const { TabPane } = Tabs
const { Text } = Typography

const KpiPerformance = () => {
    const [loadedCoordinator, setLoadedCoordinator] = useState(false);
    const [loadedTechnician, setLoadedTechnician] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [technicianKpi, setTechnicianKpi] = useState([])
    const [coordinatorKpi, setCoordinatorKpi] = useState([])
    const [dataCoordinator, setDataCoordinator] = useState([])
    const [dataTechnician, setDataTechnician] = useState([])
    const [tabsKey, setTabsKey] = useState('coordinator')
    const [yearFilter, setYearFilter] = useState(moment(Date.now()).format('YYYY'))
    const [showModalCoordinator, setShowModalCoordinator] = useState(false);
    const [showModalTechnician, setShowModalTechnician] = useState(false);
    const [kpi, setKpi] = useState();
    const [paginationPage, setPaginationPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const fetchCoordinatorKpi = (v) => {
        api("GET", `report/kpi-coordinator?year=${v}`).then((res) => {
            let data = []
            res?.data?.map((item, i) => {
                data.push({
                    name: item.name,
                    jan: {
                        ...item.kpis.filter(e => e.month === 1)[0],
                        name: item.name
                    },   
                    feb: {
                        ...item.kpis.filter(e => e.month === 2)[0],
                        name: item.name
                    },   
                    mar: {
                        ...item.kpis.filter(e => e.month === 3)[0],
                        name: item.name
                    },   
                    apr: {
                        ...item.kpis.filter(e => e.month === 4)[0],
                        name: item.name
                    },   
                    may: {
                        ...item.kpis.filter(e => e.month === 5)[0],
                        name: item.name
                    },   
                    jun: {
                        ...item.kpis.filter(e => e.month === 6)[0],
                        name: item.name
                    },   
                    jul: {
                        ...item.kpis.filter(e => e.month === 7)[0],
                        name: item.name
                    },   
                    aug: {
                        ...item.kpis.filter(e => e.month === 8)[0],
                        name: item.name
                    },   
                    sep: {
                        ...item.kpis.filter(e => e.month === 9)[0],
                        name: item.name
                    },   
                    oct: {
                        ...item.kpis.filter(e => e.month === 10)[0],
                        name: item.name
                    },   
                    nov: {
                        ...item.kpis.filter(e => e.month === 11)[0],
                        name: item.name
                    },   
                    des: {
                        ...item.kpis.filter(e => e.month === 12)[0],
                        name: item.name
                    },   
                })
            })

            setCoordinatorKpi(data)
            setDataCoordinator(res)
            setLoadedCoordinator(true)
        })
    }

    const fetchTechnicianKpi = (v) => {
        api("GET", `report/kpi-technician?year=${v}`).then((res) => {
            let data = []
            res?.data?.map((item, i) => {
                data.push({
                    name: item.name,
                    jan: {
                        ...item.kpis.filter(e => e.month === 1)[0],
                        name: item.name
                    },   
                    feb: {
                        ...item.kpis.filter(e => e.month === 2)[0],
                        name: item.name
                    },   
                    mar: {
                        ...item.kpis.filter(e => e.month === 3)[0],
                        name: item.name
                    },   
                    apr: {
                        ...item.kpis.filter(e => e.month === 4)[0],
                        name: item.name
                    },   
                    may: {
                        ...item.kpis.filter(e => e.month === 5)[0],
                        name: item.name
                    },   
                    jun: {
                        ...item.kpis.filter(e => e.month === 6)[0],
                        name: item.name
                    },   
                    jul: {
                        ...item.kpis.filter(e => e.month === 7)[0],
                        name: item.name
                    },   
                    aug: {
                        ...item.kpis.filter(e => e.month === 8)[0],
                        name: item.name
                    },   
                    sep: {
                        ...item.kpis.filter(e => e.month === 9)[0],
                        name: item.name
                    },   
                    oct: {
                        ...item.kpis.filter(e => e.month === 10)[0],
                        name: item.name
                    },   
                    nov: {
                        ...item.kpis.filter(e => e.month === 11)[0],
                        name: item.name
                    },   
                    des: {
                        ...item.kpis.filter(e => e.month === 12)[0],
                        name: item.name
                    }, 
                })
            })
            setTechnicianKpi(data)
            setDataTechnician(res)

            setLoadedTechnician(true)
        })
    }

    useEffect(() => {
        fetchCoordinatorKpi(yearFilter)
        fetchTechnicianKpi(yearFilter)

    }, []);

    const onFinishFilter = (v) => {
        setLoadedCoordinator(false)
        setLoadedTechnician(false)
        setYearFilter(moment(v.year).format('YYYY'))
        fetchCoordinatorKpi(moment(v.year).format('YYYY'))
        fetchTechnicianKpi(moment(v.year).format('YYYY'))
        setShowDrawer(false)
    }

    const resetFilter = () => {
        fetchCoordinatorKpi(yearFilter)
        fetchTechnicianKpi(yearFilter)
        setShowDrawer(false)
    }

    const showModal = (row) => {
        setKpi({
            ...row.kpi,
            name: row.name,
            month: moment(moment().format('YYYY') + '-' +row.month+ '-01').format('MMMM').toUpperCase()
        })
        
        if(row.type == 'coordinator') setShowModalCoordinator(true)
        else setShowModalTechnician(true)
    }

    const handleOk = () => {
        setShowModalCoordinator(false)
        setShowModalTechnician(false)
    };

    const exportReportCoordinator = () => {
        window.open(`${process.env.REACT_APP_API_URL}/report/kpi-coordinator/xlsx?year=${yearFilter}`);
    }

    const exportReportTechnician = () => {
        window.open(`${process.env.REACT_APP_API_URL}/report/kpi-technician/xlsx?year=${yearFilter}`);
    }

    const columns = [
        {
            title: 'No',
            align: 'center',
            render: (row) => (
                `${(parseFloat((paginationPage-1) * pageSize) + parseFloat(row.no))}`
            ),
            width: 50,
            fixed: 'left',
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            render: (row) => (
                row.toUpperCase()
            ),
            align: 'center',
            fixed: 'left',
            width: 300,
        },
        {
            title: 'January',
            dataIndex: 'jan',
            key: 'jan',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'February',
            key: 'feb',
            dataIndex: 'feb',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'March',
            key: 'mar',
            dataIndex: 'mar',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'April',
            key: 'apr',
            dataIndex: 'apr',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'May',
            key: 'may',
            dataIndex: 'may',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'June',
            key: 'jun',
            dataIndex: 'jun',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'July',
            key: 'jul',
            dataIndex: 'jul',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'August',
            key: 'aug',
            dataIndex: 'aug',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'September',
            key: 'sep',
            dataIndex: 'sep',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'October',
            key: 'oct',
            dataIndex: 'oct',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'November',
            key: 'nov',
            dataIndex: 'nov',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
        {
            title: 'December',
            key: 'des',
            dataIndex: 'des',
            align: 'center',
            render: (row) => (
                <a onClick={() => showModal(row)}>
                    {row.valueKpi}%
                </a>
            ),
            width: 150
        },
    ];

    const handleChangePagination = (el, type) => {
        if(type == 'technician') {
            setLoadedTechnician(false)
            api("GET", `report/kpi-technician?page=${el.current}&limit=${el.pageSize}&year=${yearFilter}`).then((res) => {
                let data = []
                res?.data?.map((item, i) => {
                    data.push({
                        name: item.name,
                        jan: {
                            ...item.kpis.filter(e => e.month === 1)[0],
                            name: item.name
                        },   
                        feb: {
                            ...item.kpis.filter(e => e.month === 2)[0],
                            name: item.name
                        },   
                        mar: {
                            ...item.kpis.filter(e => e.month === 3)[0],
                            name: item.name
                        },   
                        apr: {
                            ...item.kpis.filter(e => e.month === 4)[0],
                            name: item.name
                        },   
                        may: {
                            ...item.kpis.filter(e => e.month === 5)[0],
                            name: item.name
                        },   
                        jun: {
                            ...item.kpis.filter(e => e.month === 6)[0],
                            name: item.name
                        },   
                        jul: {
                            ...item.kpis.filter(e => e.month === 7)[0],
                            name: item.name
                        },   
                        aug: {
                            ...item.kpis.filter(e => e.month === 8)[0],
                            name: item.name
                        },   
                        sep: {
                            ...item.kpis.filter(e => e.month === 9)[0],
                            name: item.name
                        },   
                        oct: {
                            ...item.kpis.filter(e => e.month === 10)[0],
                            name: item.name
                        },   
                        nov: {
                            ...item.kpis.filter(e => e.month === 11)[0],
                            name: item.name
                        },   
                        des: {
                            ...item.kpis.filter(e => e.month === 12)[0],
                            name: item.name
                        }, 
                    })
                })
                setTechnicianKpi(data)
                setDataTechnician(res)
                setLoadedTechnician(true)
            })
        } else {
            setLoadedCoordinator(false)
            api("GET", `report/kpi-coordinator?page=${el.current}&limit=${el.pageSize}&year=${yearFilter}`).then((res) => {
                let data = []
                res?.data?.map((item, i) => {
                    data.push({
                        name: item.name,
                        jan: {
                            ...item.kpis.filter(e => e.month === 1)[0],
                            name: item.name
                        },   
                        feb: {
                            ...item.kpis.filter(e => e.month === 2)[0],
                            name: item.name
                        },   
                        mar: {
                            ...item.kpis.filter(e => e.month === 3)[0],
                            name: item.name
                        },   
                        apr: {
                            ...item.kpis.filter(e => e.month === 4)[0],
                            name: item.name
                        },   
                        may: {
                            ...item.kpis.filter(e => e.month === 5)[0],
                            name: item.name
                        },   
                        jun: {
                            ...item.kpis.filter(e => e.month === 6)[0],
                            name: item.name
                        },   
                        jul: {
                            ...item.kpis.filter(e => e.month === 7)[0],
                            name: item.name
                        },   
                        aug: {
                            ...item.kpis.filter(e => e.month === 8)[0],
                            name: item.name
                        },   
                        sep: {
                            ...item.kpis.filter(e => e.month === 9)[0],
                            name: item.name
                        },   
                        oct: {
                            ...item.kpis.filter(e => e.month === 10)[0],
                            name: item.name
                        },   
                        nov: {
                            ...item.kpis.filter(e => e.month === 11)[0],
                            name: item.name
                        },   
                        des: {
                            ...item.kpis.filter(e => e.month === 12)[0],
                            name: item.name
                        }, 
                    })
                })

                setCoordinatorKpi(data)
                setDataCoordinator(res)
                setLoadedCoordinator(true)
            })
        }
        setPaginationPage(el.current)
        setPageSize(el.pageSize)
    }

    const handleSearch = (el, type) => {
        var payload = {
            search: el,
            year: yearFilter
        }

        if(el.length < 5) return

        if(type == 'technician') {
            setLoadedTechnician(false)
            api("GET", `report/kpi-technician`, payload).then((res) => {
                let data = []
                res?.data?.map((item, i) => {
                    data.push({
                        name: item.name,
                        jan: {
                            ...item.kpis.filter(e => e.month === 1)[0],
                            name: item.name
                        },   
                        feb: {
                            ...item.kpis.filter(e => e.month === 2)[0],
                            name: item.name
                        },   
                        mar: {
                            ...item.kpis.filter(e => e.month === 3)[0],
                            name: item.name
                        },   
                        apr: {
                            ...item.kpis.filter(e => e.month === 4)[0],
                            name: item.name
                        },   
                        may: {
                            ...item.kpis.filter(e => e.month === 5)[0],
                            name: item.name
                        },   
                        jun: {
                            ...item.kpis.filter(e => e.month === 6)[0],
                            name: item.name
                        },   
                        jul: {
                            ...item.kpis.filter(e => e.month === 7)[0],
                            name: item.name
                        },   
                        aug: {
                            ...item.kpis.filter(e => e.month === 8)[0],
                            name: item.name
                        },   
                        sep: {
                            ...item.kpis.filter(e => e.month === 9)[0],
                            name: item.name
                        },   
                        oct: {
                            ...item.kpis.filter(e => e.month === 10)[0],
                            name: item.name
                        },   
                        nov: {
                            ...item.kpis.filter(e => e.month === 11)[0],
                            name: item.name
                        },   
                        des: {
                            ...item.kpis.filter(e => e.month === 12)[0],
                            name: item.name
                        }, 
                    })
                })
                setTechnicianKpi(data)
                setDataTechnician(res)
                setLoadedTechnician(true)
            })
        } else {
            setLoadedCoordinator(false)
            api("GET", `report/kpi-coordinator`, payload).then((res) => {
                let data = []
                res?.data?.map((item, i) => {
                    data.push({
                        name: item.name,
                        jan: {
                            ...item.kpis.filter(e => e.month === 1)[0],
                            name: item.name
                        },   
                        feb: {
                            ...item.kpis.filter(e => e.month === 2)[0],
                            name: item.name
                        },   
                        mar: {
                            ...item.kpis.filter(e => e.month === 3)[0],
                            name: item.name
                        },   
                        apr: {
                            ...item.kpis.filter(e => e.month === 4)[0],
                            name: item.name
                        },   
                        may: {
                            ...item.kpis.filter(e => e.month === 5)[0],
                            name: item.name
                        },   
                        jun: {
                            ...item.kpis.filter(e => e.month === 6)[0],
                            name: item.name
                        },   
                        jul: {
                            ...item.kpis.filter(e => e.month === 7)[0],
                            name: item.name
                        },   
                        aug: {
                            ...item.kpis.filter(e => e.month === 8)[0],
                            name: item.name
                        },   
                        sep: {
                            ...item.kpis.filter(e => e.month === 9)[0],
                            name: item.name
                        },   
                        oct: {
                            ...item.kpis.filter(e => e.month === 10)[0],
                            name: item.name
                        },   
                        nov: {
                            ...item.kpis.filter(e => e.month === 11)[0],
                            name: item.name
                        },   
                        des: {
                            ...item.kpis.filter(e => e.month === 12)[0],
                            name: item.name
                        }, 
                    })
                })
                setCoordinatorKpi(data)
                setDataCoordinator(res)
                setLoadedCoordinator(true)
            })
        }
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Report KPI" breadcrumbs={[['Report'], ['Report KPI']]} />
                <Card title={
                    <div className="full-width">
                        <Row justify="space-between">
                            <Col>
                                <Text strong style={{ float: 'left' }}>Report KPI Period {yearFilter}</Text>
                            </Col>
                            <Col>
                                <Space>
                                    <Button onClick={() => setShowDrawer(true)} shape="round" icon={<FilterOutlined />} size="middle" />
                                    <Button onClick={() => tabsKey === 'technician' ? exportReportTechnician() : exportReportCoordinator()} shape="round" size="middle" >
                                        <FileExcelOutlined /> Generate Excel
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </div>}
                >

                    <Tabs defaultActiveKey={'coordinator'} onChange={(v) => setTabsKey(v)} type='card'>
                        
                        <TabPane tab='Coordinator' key='coordinator'>
                            {loadedCoordinator ?
                                <DataTable size='small' bordered 
                                    dataSource={coordinatorKpi}
                                    columns={columns}
                                    onChange={(e) => handleChangePagination(e, 'coordinator')}
                                    onSearch={(e) => handleSearch(e, 'coordinator')}
                                    pagination={{
                                        total: dataCoordinator.total,
                                        pageSize: pageSize,
                                        current: paginationPage
                                    }} />
                                :
                            <Spinner /> }
                        </TabPane>

                        <TabPane tab='Technician' key='technician'>
                            {loadedTechnician ?
                                <DataTable size='small' bordered 
                                    dataSource={technicianKpi} 
                                    columns={columns}
                                    onChange={(e) => handleChangePagination(e, 'technician')}
                                    onSearch={(e) => handleSearch(e, 'technician')}
                                    pagination={{
                                        total: dataTechnician.total,
                                        pageSize: pageSize,
                                        current: paginationPage
                                    }} />
                                :
                                <Spinner /> 
                            }
                        </TabPane>
                    </Tabs>
                </Card>

                {
                    showDrawer ?
                        <Drawer
                            title="Search Ticket"
                            placement="right"
                            width={400}
                            onClose={() => setShowDrawer(false)}
                            open={showDrawer}
                        >
                            <Form onFinish={onFinishFilter} layout='vertical'>
                                <Form.Item label="Year" name="year">
                                    <DatePicker style={{ width: '100%' }} picker="year" defaultValue={moment(Date.now())} />
                                </Form.Item>

                                <Space>
                                    <Form.Item>
                                        <Button type="primary" htmlType='submit'>
                                            Submit
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button onClick={resetFilter} danger type="primary">
                                            Reset
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Form>
                        </Drawer>
                        : null
                }


                {showModalCoordinator ? 
                    <Modal title={`KPI ${kpi.month} - ${kpi.name}`} open={showModalCoordinator} onOk={handleOk} onClose={handleOk}>

                        <Row justify='space-between' className='mb-5'>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#dc3545" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>OUTSTANDING</Text>
                                    </div>
                                    <div className='mb-3'>
                                        <FileDoneOutlined style={{ color: 'white', fontSize: 14 }} /> 
                                        <Text style={{ fontSize: 14, color: 'white' }}>{kpi.outstanding ?? 0} WO ({kpi.outstanding_pct}%)</Text>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#28a745" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>ACHIEVEMENT</Text>
                                    </div>
                                    <div className='mb-3'>
                                        <FileDoneOutlined style={{ color: 'white', fontSize: 14 }} /> 
                                        <Text style={{ fontSize: 14, color: 'white' }}>{kpi.closed ?? 0} WO ({kpi.closed_pct}%)</Text>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#17a2b8" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>ASSIGNMENT</Text>
                                    </div>
                                    <div className='mb-3'>
                                        <FileDoneOutlined style={{ color: 'white', fontSize: 14 }} /> 
                                        <Text style={{ fontSize: 14, color: 'white' }}>{kpi.assignment ?? 0} DAY ({kpi.assignment_pct}%)</Text>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Modal>
                : null }


                {showModalTechnician ? 
                    <Modal title={`KPI ${kpi.month} - ${kpi.name}`} open={showModalTechnician} onOk={handleOk} onClose={handleOk}>

                        <Text style={{ fontSize: 14, marginBottom: 15 }}>TARGET COMPLETION</Text>
                        <Row justify='space-between' className='mb-5'>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#ffc107" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>LOW</Text>
                                    </div>
                                    <div className='mb-3'>
                                        <FileDoneOutlined style={{ color: 'white', fontSize: 14 }} /> 
                                        <Text style={{ fontSize: 14, color: 'white' }}>{kpi.target_low ?? 0} DAY ({kpi.target_low_pct}%)</Text>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#28a745" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>MEDIUM</Text>
                                    </div>
                                    <div className='mb-3'>
                                        <FileDoneOutlined style={{ color: 'white', fontSize: 14 }} /> 
                                        <Text style={{ fontSize: 14, color: 'white' }}>{kpi.target_medium ?? 0} DAY ({kpi.target_medium_pct}%)</Text>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#dc3545" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>HIGH</Text>
                                    </div>
                                    <div className='mb-3'>
                                        <FileDoneOutlined style={{ color: 'white', fontSize: 14 }} /> 
                                        <Text style={{ fontSize: 14, color: 'white' }}>{kpi.target_high ?? 0} DAY ({kpi.target_high_pct}%)</Text>
                                    </div>
                                </Card>
                            </Col>
                        </Row>

                        <Text style={{ fontSize: 14, marginBottom: 15 }}>ACHIEVEMENT</Text>
                        <Row justify='space-between' className='mb-5'>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#ffc107" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>LOW</Text>
                                    </div>
                                    <div className='mb-3'>
                                        <FileDoneOutlined style={{ color: 'white', fontSize: 14 }} /> 
                                        <Text style={{ fontSize: 14, color: 'white' }}>{kpi.achievement_low ?? 0} WO ({kpi.achievement_low_pct}%)</Text>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#28a745" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>MEDIUM</Text>
                                    </div>
                                    <div className='mb-3'>
                                        <FileDoneOutlined style={{ color: 'white', fontSize: 14 }} /> 
                                        <Text style={{ fontSize: 14, color: 'white' }}>{kpi.achievement_medium ?? 0} WO ({kpi.achievement_medium_pct}%)</Text>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={7}>
                                <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#dc3545" }}>
                                    <div className='mb-3'>
                                        <Text style={{ fontSize: 14, color: 'white' }}>HIGH</Text>
                                    </div>
                                    <div className='mb-3'>
                                        <FileDoneOutlined style={{ color: 'white', fontSize: 14 }} /> 
                                        <Text style={{ fontSize: 14, color: 'white' }}>{kpi.achievement_high ?? 0} WO ({kpi.achievement_high_pct}%)</Text>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Modal>
                : null }
            </Card>
        </div>
    )
}

export default KpiPerformance